.react-daterange-picker,
.react-datetimerange-picker,
.react-date-picker {
  display: inline-flex;
  position: relative;
  width: 100%;
}

.react-daterange-picker,
.react-daterange-picker *,
.react-daterange-picker *:before,
.react-daterange-picker *:after,
.react-datetimerange-picker,
.react-datetimerange-picker *,
.react-datetimerange-picker *:before,
.react-datetimerange-picker *:after,
.react-date-picker,
.react-date-picker *,
.react-date-picker *:before,
.react-date-picker *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-daterange-picker--disabled,
.react-date-picker--disabled,
.react-datetimerange-picker--disabled {
  background-color: var(--chakra-colors-primary-100);
  color: var(--chakra-colors-primary-300);
}

.react-daterange-picker__wrapper,
.react-datetimerange-picker__wrapper,
.react-date-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  border: 1px solid;
  border-color: var(--chakra-colors-chakra-border-color);
  cursor: pointer;
  height: var(--chakra-sizes-11);
  min-height: 24px;
  border-radius: var(--chakra-radii-md);
  padding-inline: 0.25rem;
  background: white;
}

.react-daterange-picker__wrapper:hover,
.react-datetimerange-picker__wrapper:hover,
.react-date-picker__wrapper:hover {
  border-color: var(--chakra-colors-primary-500);
}


.react-daterange-picker__inputGroup,
.react-datetimerange-picker__inputGroup,
.react-date-picker__inputGroup {
  min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2);
  height: 100%;
  padding: 0 2px;
}

.react-daterange-picker__inputGroup:last-of-type,
.react-datetimerange-picker__inputGroup:last-of-type,
.react-date-picker__inputGroup {
  flex-grow: 1;
}


.react-daterange-picker__inputGroup__divider,
.react-datetimerange-picker__inputGroup__divider,
.react-datetime-picker__inputGroup__divider,
.react-date-picker__inputGroup__divider {
  padding: 1px 0;
  white-space: pre;
  color: var(--chakra-colors-gray-800);
}

.react-daterange-picker__inputGroup__divider,
.react-daterange-picker__inputGroup__leadingZero,
.react-datetimerange-picker__inputGroup__divider,
.react-datetimerange-picker__inputGroup__leadingZero,
.react-date-picker__inputGroup__divider,
.react-date-picker__inputGroup__leadingZero {
  display: inline-block;
}

.react-daterange-picker__inputGroup__input,
.react-datetimerange-picker__inputGroup__input,
.react-date-picker__inputGroup__input {
  min-width: 0.54em;
  height: calc(100% - 2px);
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  color: currentColor;
  font: inherit;
  box-sizing: content-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  outline: none;
  cursor: text;
}

.react-daterange-picker__inputGroup__input::-webkit-outer-spin-button,
.react-daterange-picker__inputGroup__input::-webkit-inner-spin-button,
.react-datetimerange-picker__inputGroup__input::-webkit-outer-spin-button,
.react-datetimerange-picker__inputGroup__input::-webkit-inner-spin-button,
.react-date-picker__inputGroup__input::-webkit-outer-spin-button,
.react-date-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.react-daterange-picker__inputGroup__input:invalid,
.react-datetimerange-picker__inputGroup__input:invalid,
.react-date-picker__inputGroup__input:invalid {
  background: var(--chakra-colors-blue-100);
}

.react-daterange-picker__inputGroup__input--hasLeadingZero,
.react-datetimerange-picker__inputGroup__input--hasLeadingZero,
.react-date-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px + 0.54em);
}

.react-daterange-picker__button,
.react-datetimerange-picker__button,
.react-date-picker__button {
  border: 0;
  background: transparent;
  padding: 4px 6px;
}

.react-daterange-picker__button:enabled,
.react-datetimerange-picker__button:enabled,
.react-date-picker__button:enabled {
  cursor: pointer;
}

.react-daterange-picker__button:enabled:hover .react-daterange-picker__button__icon,
.react-daterange-picker__button:enabled:focus .react-daterange-picker__button__icon,
.react-datetimerange-picker__button:enabled:hover .react-datetimerange-picker__button__icon,
.react-datetimerange-picker__button:enabled:focus .react-datetimerange-picker__button__icon
.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
  stroke: var(--chakra-colors-primary-500);
}

.react-daterange-picker__button:disabled .react-daterange-picker__button__icon,
.react-datetimerange-picker__button:disabled .react-datetimerange-picker__button__icon,
.react-date-picker__button:disabled .react-date-picker__button__icon {
  stroke: var(--chakra-colors-gray-200);
}

.react-daterange-picker__button svg,
.react-datetimerange-picker__button svg,
.react-date-picker__button svg {
  display: inherit;
  stroke: var(--chakra-colors-gray-600);
}

.react-datetimerange-picker__range-divider,
.react-daterange-picker__range-divider {
  padding: 0 1rem;
  flex: 1;
  text-align: center;
}

.react-daterange-picker__calendar,
.react-datetimerange-picker__calendar,
.react-date-picker__calendar {
  width: 22rem;
  max-width: 90vw;
  z-index: 1;
}

.react-daterange-picker__calendar--closed,
.react-datetimerange-picker__calendar--closed,
.react-datetimerange-picker__clock--closed,
.react-date-picker__calendar--closed {
  display: none;
}

.react-daterange-picker__calendar .react-calendar,
.react-datetimerange-picker__calendar .react-calendar,
.react-date-picker__calendar .react-calendar {
  border-width: 1px;
}

.react-datetimerange-picker__inputGroup__amPm {
  font: inherit;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  appearance: menulist;
}

.react-datetimerange-picker__clock {
  width: 150px;
  height: 150px;
  max-width: 100vw;
  border: 1px solid;
  background: white;
  border-color: var(--chakra-colors-chakra-border-color);
  border-radius: var(--chakra-radii-full);
  z-index: 1;
}

/* Calendar */
.react-calendar {
  width: 22rem;
  max-width: 100%;
  background: white;
  box-shadow: var(--chakra-shadows-sm);
  line-height: 1.125em;
  border: 1px solid;
  border-color: var(--chakra-colors-chakra-border-color);
  border-radius: var(--chakra-radii-md);
  overflow: hidden;
  position: relative;
  top: 0.5rem;
}

.react-calendar--doubleView {
  width: 44rem;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 3rem;
  padding-top: 0.5rem;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: var(--chakra-colors-gray-200);
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font: inherit;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font: inherit;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
  /* color: #d10000; */
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: var(--chakra-colors-gray-500);
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
  font: inherit;
  font-size: 0.833em;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: var(--chakra-colors-gray-200);
}

.react-calendar .react-calendar__tile--now {
  border: 1px solid var(--chakra-colors-primary-500);
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: var(--chakra-colors-gray-200);
}

.react-calendar__tile--hasActive {
  background: var(--chakra-colors-gray-300);
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: var(--chakra-colors-gray-200);
}

.react-calendar__tile--active {
  background: var(--chakra-colors-gray-400);
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: var(--chakra-colors-gray-400);
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: var(--chakra-colors-gray-200);
}

/* Clock */

.react-clock {
  display: block;
  position: relative;
}

.react-clock,
.react-clock *,
.react-clock *:before,
.react-clock *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-clock__face {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 1px solid black;
  border-radius: 50%;
}

.react-clock__hand {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 50%;
}

.react-clock__hand__body {
  position: absolute;
  background-color: black;
  transform: translateX(-50%);
}

.react-clock__mark {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 50%;
}

.react-clock__mark__body {
  position: absolute;
  background-color: black;
  transform: translateX(-50%);
}

.react-clock__mark__number {
  position: absolute;
  left: -40px;
  width: 80px;
  text-align: center;
}

.react-clock__second-hand__body {
  background-color: red;
}